.ok2land__container-settings {
    display: block;
    margin-top: 72px;
    margin-bottom: 72px;
    max-width: 1024px !important;


    .page-blocker {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 9999;
      }

    .dashbord__top-section {
        width: 100%;
        gap: 24px;
        display: flex;
    }

    .ok2land__row-settings {
        max-width: 960px;
        min-height: 650px;
        margin: auto;

        .Toastify {
            height: fit-content;
        }

        .right__border {
            border-right: 1px solid $ok2land_light_blue;
            padding-right: 32px;
            position: relative;
            min-height: 500px;

            .ok2land__logout-box {
                padding: 10px 12px;
                position: absolute;
                bottom: 0;
                cursor: pointer;

                span {
                    margin-left: 8px;
                    font-family: $fontSpaceGrotesk;
                    font-weight: 700;
                    font-size: 15px;
                    letter-spacing: 0.28px;
                    color: $ok2land_dark_blue;
                }
            }
        }

        .ok2land__aircraft-title {
            display: flex;
            margin-bottom: 24px;
            justify-content: space-between;

            span {
                margin-left: 12px;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 15px;
                color: $ok2land_gray;
            }
        }

        .settings-option {
            color: $ok2land_dark_blue;
            font-family: $fontSpaceGrotesk;
            font-weight: 700;
            font-size: 15px;
            padding: 10px 12px;
            cursor: pointer;
            margin-bottom: 16px;
        }

        .selected {
            background-color: $ok2land_white;
        }

        .options__col {
            padding-left: 32px;
            position: relative;

            .ok2land__settings-config-col {
                height: 100%;
                position: relative;

                .ok2land__settings-config-col-form {
                    margin-bottom: 108px;

                    .pricing-header{
                        color: $ok2land_gray;
                        font-family: $fontSpaceGrotesk;
                        font-weight: 700;
                        font-size: 14px;                    
                    }
                    .pricing-weight{
                        color: $ok2land_black;
                        font-family: $fontSpaceGrotesk;
                        font-weight: 700;
                        font-size: 16px;    
                        line-height: 50px;
                        margin-bottom: 0;
                        
                        span {
                            color: $neutral500;
                            font-weight: 400;
                        }
                    }
                }
                .col-from{
                    flex: 0 0 auto;
                    width: 100%;
                }
                .col-to{
                    flex: 0 0 auto;
                    width: 100%;
                }
                @media (min-width: 768px) {
                    .col-from{
                        flex: 0 0 auto;
                        width: 75px;
                    }
                    .col-to{
                        flex: 0 0 auto;
                        width: calc(33.33333333% - 75px);
                    }
                }
            }

            .ok2land__steps {
                margin-bottom: 60px;
            }

            .ok2land__settings-config-col-title {
                color: $ok2land_black;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 15px;
                letter-spacing: 1.4px;
                margin-bottom: 24px;
            }

            .save-button  {
                width: 160px;
                height: 56px;
                padding: 12px;
                border-radius: 16px;
                position: absolute;
                display: block;
                right: 0;
                bottom: 0;
                background-color: $neutral300;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 15px;
                letter-spacing: 1.8px;
                color: $neutral400;
                border: none;
                cursor: not-allowed;
            }

            .save-active {
                background-color: $ok2land_black;
                color: $ok2land_white;
                cursor: pointer;
            }

            label {
                font-family: $fontSpaceGrotesk;
                font-weight: 400;
                font-size: 15px;
                color: $ok2land_black;
                margin-bottom: 8px;
            }

            input {
                border-radius: 2px;
                background-color: $ok2land_white;
                height: 50px;
                padding: 10px;
                border: 0;
                margin-bottom: 24px;
            }

            .password-hint {
                max-width: 435px;
            }

            .errorMessage {
                display: block;
                margin: 4px 0 16px;                
                font-family: $fontSpaceGrotesk;
                color: $ok2land_red;
                font-size: 15px;
                font-weight: 700;
                font-style: italic;
            }

            label { 
                display: block;
                margin-bottom: 9px;
                font-size: 15px;
                font-weight: 400;
                font-family: $fontSpaceGrotesk;
                color: $ok2land_black;
            }
    

            input {
                background-color: #DBF1F599;
                border: 0;
                border-radius: 2px;
                height: 50px;
                width: 100%;
                padding: 0 16px;
                font-size: 17px;
                font-weight: 700;
                font-family: $fontSpaceGrotesk;
            }

            input:not(:placeholder-shown),
            input:focus {
                background-color: #FFFFFF;
                outline: 0;
            }
    
            input:first-of-type {
                margin-bottom: 24px;
            }

            .ok2land__btn-add {
                width: 122px;
                height: 34px;
                border-radius: 4px;
                border: none;
                font-family: $fontSpaceGrotesk;
                font-size: 13px;
                color: #FFFFFF;
                font-weight: 700;
                letter-spacing: 2px;
                background: #1D1D29;
                text-transform: uppercase;
                margin-top: 10px;
            }

            .ok2land__btn-add.disabled {
                opacity: 0.1;
            }

            .ok2land__days-week {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 30px;
                margin-bottom: 16px;
                position: relative;

                .ok2land__day-week {
                    width: 75px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    font-weight: 400;
                    font-family: $fontSpaceGrotesk;
                    color: #1D1D29CC;
                    border: 1px solid #DBF1F599;
                    background-color: #DBF1F599;
                    cursor: pointer;
                }
                .ok2land__day-week.selected {
                    font-weight: 700;
                    font-family: $fontSpaceGrotesk;
                    color: #1D1D29;
                    border: 1px solid #D4E5E8;
                    background-color: #FFFFFF;
                }   
                .ok2land__day-week.disabled {
                    opacity: 0.2;
                    cursor: default;
                }
                .btn-delete-schedule {
                    position: absolute;
                    right: -40px;
                    top: 10px;
                    border: none;
                    background-color: transparent;

                    img {
                        width: 20px;
                        object-fit: contain;
                    }
                }  
            }

            .ok2land__sc-club {
                display: flex;
                gap: 10px;

                .ok2land__scc-10 {
                    width: 75%;
                }

                .ok2land__scc-2 {
                    width: 25%;
                }

                .ok2land__scc-5 {
                    width: 50%;
                }
            }

            .ok2land__request-land {
                height: 80px;
                width: 100%;
                background-color: #DBF1F599;
                display:flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px;
                margin-top: 20px;
                border-radius: 8px;

                .request_title {
                    margin:0;
                    font-size: 15px;
                    font-weight: 400;
                    font-family: "Space Grotesk";
                    color: $ok2land_black;
                    width: auto;
                }

                label {
                    position: relative;
                    display: inline-block;
                    height: 22px;
                    width: 40px;
                }
        
                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                
                span {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #2E2E3F14;
                    transition: 0.3s;
                    border-radius: 30px;
                }

                span:before {
                    position: absolute;
                    content: "";
                    height: 22px;
                    width: 22px;
                    left: 0;
                    bottom: 0;
                    background-color: #2E2E3F3D;
                    border-radius: 50%;
                    transition: 0.3s;
                }
                  
                input:checked + span {
                    background-color: #2E2E3F3D;
                }
                  
                input:checked + span:before {
                    transform: translateX(19px);
                    background-color: #1D1D29;
                }
            }

            .password-input-container {
                position: relative;

                .password-toggle-icon {
                    position: absolute;
                    top: 12px;
                    right: 24px;
                    cursor: pointer;
                }
            }

            .pricing-title-row {
                margin-bottom: 16px;

                div {
                    padding-right: 0;
                }

                div:nth-child(2) {
                    margin-left: -4px;
                }

                div:nth-child(3) {
                    margin-left: -10px;
                }
            }

            .pricing-input-row {
                margin: 24px 0;
                display: flex;
                align-items: center;

                input {
                    margin: 0;
                }

                div {
                    padding-left: 0;
                }
            }

            .ok2land__landing-col-title {
                span {
                    font-size: 15px;
                    font-weight: 700;
                    font-family: "Space Grotesk";
                    color: $ok2land_gray;
                }
            }

            .row-add-mtow {
                display: flex;
                gap: 24px;
                align-items: center;
                cursor: pointer;
                width: auto;
                margin: 24px 0;

                .add-mtow {
                    height: 50px;
                    width: 50px;
                    border-radius: 2px;
                    background-color: $ok2land_white;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }

                span {
                    font-size: 15px;
                    font-weight: 700;
                    font-family: "Space Grotesk";
                    color: rgba(46, 46, 63, 0.64);
                }
            }

            .from-input {
                font-size: 17px;
                font-weight: 700;
                font-family: "Space Grotesk";
                color: $ok2land_black;

                .bold-from {
                    font-weight: 400;
                    color: rgba(46, 46, 63, 0.64);
                }
            }

            .to-input{
                position: relative;
            }

            .to-input::before {
                content: 'kg';
                position: absolute;
                display: block;
                right: 24px;
                top: 12px;
                z-index: 2;
                font-size: 17px;
                font-weight: 400;
                font-family: "Space Grotesk";
                color: rgba(46, 46, 63, 0.48);
            }

            .cost-input{
                position: relative;
            }

            .cost-input::before {
                content: '€';
                position: absolute;
                display: block;
                right: 24px;
                top: 12px;
                z-index: 2;
                font-size: 17px;
                font-weight: 400;
                font-family: "Space Grotesk";
                color: rgba(46, 46, 63, 0.48);
            }

            .pricing-parking-row {
                display: flex;
                align-items: center;
                margin: 8px 0 24px;
             
                input {
                    margin: 0;
                    position: relative;
                }

                .pricing-parking-input {
                    position: relative;
                }

                .pricing-parking-input:not(.input-weight)::before {
                    content: '€';
                    position: absolute;
                    display: block;
                    right: 24px;
                    top: 12px;
                    z-index: 2;
                    font-size: 17px;
                    font-weight: 400;
                    font-family: "Space Grotesk";
                    color: rgba(46, 46, 63, 0.48);
                }

                .pricing-parking-input.input-weight::before {
                    content: 'kg';
                    position: absolute;
                    display: block;
                    right: 24px;
                    top: 12px;
                    z-index: 2;
                    font-size: 17px;
                    font-weight: 400;
                    font-family: "Space Grotesk";
                    color: rgba(46, 46, 63, 0.48);
                }

                .pricing-parking-title {
                    font-size: 17px;
                    font-weight: 700;
                    font-family: "Space Grotesk";
                    color: $ok2land_black;
                    letter-spacing: 0.3px;
                }

                .pricing-parking-subtitle {
                    font-size: 15px;
                    font-weight: 700;
                    font-family: "Space Grotesk";
                    color: $ok2land_gray;
                    letter-spacing: 0.3px;
                }
            }

            .second-row {
                margin-top: 52px;
            }

            .parking-per-night {
                padding: 20px 22px;
                border-radius: 2px;
                background-color: $ok2land_white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 24px;

                &:nth-last-of-type(1) {
                    margin-bottom: 80px;
                }

                span {
                    font-size: 17px;
                    font-weight: 700;
                    font-family: "Space Grotesk";
                    color: $ok2land_black;
                    letter-spacing: 0.3px;
                    margin-left: 8px;
                    display: block;
                    width: 40px;
                }

                img {
                    width: 22px;
                    height: 22px;
                    padding: 0;
                    filter: invert(1);
                    object-fit: contain;
                    margin-right: 5px;
                }

                div {
                    display: flex;
                    width: auto;

                    .service-input, .service-input-clean {
                        position: relative;
                    }

                    .service-input::after {
                        content: '€/L';
                        position: absolute;
                        right: 12px;
                        top: 12px;
                        z-index: 2;
                        font-size: 17px;
                        font-weight: 400;
                        font-family: "Space Grotesk";
                        color: #99B8BE;
                    }

                    .service-input-clean::after {
                        content: '€';
                        position: absolute;
                        right: 12px;
                        top: 12px;
                        z-index: 2;
                        font-size: 17px;
                        font-weight: 400;
                        font-family: "Space Grotesk";
                        color: #99B8BE;
                    }

                    input {
                        margin: 0;
                        background-color: rgba(219, 241, 245, 0.60);
                        background-color: 2px;
                    }
                }
                
                .ok2land__request-land {
                    background-color: transparent;
                    height: auto;
                    margin: 12px 0 0 12px;
                    padding: 0;

                    input:checked + span:before {
                        background-color: #99B8BE;
                    }
                }
            }

            .same-row {
                display: block;
                
                .ok2land__request-land {
                    input:checked + span:before {
                        background-color: #99B8BE !important;
                    }
                }
            }

            .ok2land__settings-stripe {
                display: flex;
                width: 100%;
                min-height: 80px;
                border-radius: 20px;
                background-color: $ok2land_white;
                box-shadow: 0px 4px 16px -12px #1A1B1D14;
                padding: 16px 24px;
                margin-bottom: 24px;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                div {
                    width: fit-content;

                    img {
                        margin-right: 8px;
                    }
                }

                span {
                    color: $ok2land_black;
                    font-family: $fontSpaceGrotesk;
                    font-weight: 700;
                    font-size: 17px;
                }

                .select__stripe {
                    width: 8px;
                    padding: 0;
                }
            }

            .ok2land__settings-stripe:hover {
                box-shadow: 2px 6px 18px -4px #1A1B1D14;
            }

            .ok2land__settings-stripe-steps {
                display: block;

                .stripe__steps {
                    margin-bottom: 12px;
                    
                    .stripe__step-1 {
                        color: $ok2land_purple;
                        font-family: $fontSpaceGrotesk;
                        font-weight: 700;
                        font-size: 15px;
                        letter-spacing: 0.3px;
                        margin-right: 6px;
                    }

                    .stripe__step-2 {
                        color: $ok2land_gray;
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 15px;
                        letter-spacing: 0.6px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}