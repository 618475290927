/* Basic colors */
$ok2land_white: #FFFFFF;
$ok2land_white1: #F0F9FB;
$ok2land_white2: #F7F9F8;
$ok2land_black: #1D1D29;
$ok2land_white: #FFFFFF;

/* Main colors */
$ok2land_gray: #54585C;
$ok2land_opac_gray: #54585CA3;
$ok2land_red: #EC555D;
$ok2land_orange: #EE925E;
$ok2land_green: #C8EDAC;
$ok2land_light_green: #9FD3DA;
$ok2land_green: #C8EDAC;
$ok2land_dark_blue: #25445A;
$ok2land_light_blue: #EAF1F2;
$ok2land_purple: #6D65FF;


// Gray Scale
$neutral100: #F8F9FA;
$netraul150: #EEF2F5;
$neutral200: #E9ECEF;
$neutral300: #DEE2E6;
$neutral400: #CED4DA;
$neutral500: #ADB5BD;
$neutral600: #6C757D;
$neutral700: #495057;
$neutral800: #343A40;
$neutral900: #212529;