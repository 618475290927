.ok2land__header {
    margin: 0 24px;
    border-bottom: 1px solid #BCD8DDA6;

    .ok2land__header-inner {
        display: flex;

        .ok2land__header-logo {
            display: inline-flex;
            width: 20%;

            img {
                padding: 12px 6px;
            }
        }

        .ok2land__header-items {
            display: inline-flex;
            justify-content: flex-end;
            align-items: center;
            width: 80%;

            .ok2land__header-item {
                cursor: pointer;
                margin-right: 56px;
                display: flex;
                height: 100%;
                align-items: center;
                position: relative;
                text-decoration: none;
                color: $ok2land_opac_gray;
            }

            .ok2land__header-item:last-child() {
                margin-right: 0;
            }

            .active {
                color: $ok2land_gray !important;

                span {
                    font-weight: 700 !important;
                }
            }

            .active::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 24px;
                height: 9px;
                background-color: $ok2land_red;
            }

            span {
                font-family: $fontSpaceGrotesk;
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}