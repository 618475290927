.ok2land__container {
    display: block;
    margin-top: 72px;

    .dashbord__top-section {
        width: 100%;
        gap: 24px;
        display: flex;
    }

    .ok2land__row {
        max-width: 100%;
        margin: auto;

        .ok2land__aircraft-title {
            display: flex;
            margin-bottom: 24px;
            justify-content: space-between;

            span {
                margin-left: 12px;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 14px;
                color: $ok2land_gray;
            }
        }

        .ok2land__dashboard-title-box {
            margin-bottom: 56px;

            .ok2land__dashboard-top-section {
                display: flex;
                gap: 24px;
                justify-content: space-between;

                .analytics__title {
                    margin-bottom: 16px;
                }

                .ok2land__dashboard-top-section-col{
                    display: block;
                    width: calc(50% - 12px);
                    height: 180px;
                    background-color: $ok2land_light_blue;
                    border-radius: 20px;
                    padding: 24px;

                    span {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 12px;
                        color: #2E2E3F7A;
                    }
                    
                    .ok2land__analytics-col-title{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 24px;

                        span {
                            font-family: $fontSpaceGrotesk;
                            font-weight: 700;
                            font-size: 14px;
                            color: $ok2land_opac_gray;
                            letter-spacing: 1.4px;
                        }
                    }

                    .ok2land__analytics-col-result {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 700;
                        font-size: 40px;
                        color: $ok2land_black;
                        letter-spacing: 4px;
                        margin-bottom: 6px;
                        display: block;
                    }

                    .ok2land__analytics-col-change {
                        img {
                            margin-right: 8px;
                        }
                        
                        span {
                            font-family: $fontSpaceGrotesk;
                            font-weight: 700;
                            font-size: 16px;
                            color: $ok2land_black;
                            letter-spacing: 1.6px;
                        }
                    }
                }

                span {
                    color: $ok2land_gray;
                    font-family: $fontSpaceGrotesk;
                    font-weight: 700;
                    font-size: 14px;
                    letter-spacing: 1.4px;
                    margin-left: 8px;
                }
            }
        }
    }
}

.ok2land__dashboard-container {
    display: inline-block;
    background-color: $ok2land_light_blue;
    max-width: 100vw !important;
    width: 100vw;
    padding-top: 72px;
    padding-bottom: 72px;

    .table-header{
        padding: 0 24px;
    }

    .ok2land__row {
        max-width: 100%;
        margin: auto;
        background-color: $ok2land_light_blue;

        .ok2land__dashboard-history-title {
            margin-bottom: 16px;

            span {
                color: $ok2land_gray;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 1.4px;
                margin-left: 8px;
            }
        }

        .ok2land__dashboard-history-top {
            display: flex;
            justify-content: space-between;

            input {
                width: 350px;
                height: 40px;
                padding: 6px 12px 6px 36px;
                background-color: $ok2land_white;
                color: $neutral500;
                font-family: $fontSpaceGrotesk;
                font-weight: 500;
                font-size: 12px;
                border: none;
                background-image: url('../../images/Search.svg');
                background-repeat: no-repeat;
                background-position: 12px center;
            }

            button {
                background-image: url('../../images/Search.svg');
                background-repeat: no-repeat;
                background-position: 12px center;
                height: 40px;
                padding: 6px 12px 6px 36px;
                background-color: $ok2land_white;
                color: $ok2land_dark_blue;
                font-family: $fontSpaceGrotesk;
                font-weight: 500;
                font-size: 12px;
                border: none;
                border-radius: 4px;
            }
        }

        .ok2land__dashboard-item-cols {
            span {
                font-family: $fontSpaceGrotesk;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: $ok2land_black;
            }
        }

        .pob__col {
            display: flex;
            gap: 8px;
            align-items: center;

            span {
                font-family: $fontSpaceGrotesk;
                font-size: 16px;
                font-weight: 700;
                color: #3E4851E0;
            }
        }

        .date__col {
            span {
                font-family: $fontSpaceGrotesk;
                font-size: 12px;
                font-weight: 700;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                color: $ok2land_opac_gray;
            }
        }
    }

    .ok2land__row-item-box {
        margin: 6px 0;
        background-color: $ok2land_white;
        border-radius: 20px;
        padding: 10px 24px;

        .status__col {
            display: flex;
            justify-content: space-between;

            .payment__status {
                img {
                    margin-right: 6px;
                }
            }

            img {
                cursor: pointer;
            }

            select:focus-visible{
                outline: 0;
            }

            .payment__info {
                font-family: $fontSpaceGrotesk;
                font-size: 10px;
                font-weight: 700;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $ok2land_orange;
                border: 0;
            }
            .payment__success {
                font-family: $fontSpaceGrotesk;
                font-size: 10px;
                font-weight: 700;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: '#C8EDAC';
                border: 0;
            }
        }
    }
}