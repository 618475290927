.ok2land__signup-box {
    display: flex;

    .ok2land__logo {
        width: auto;
        height: auto;
        position: absolute;
        left: 32px;
        top: 24px;
    }

    .ok2land__signup-form {
        width: calc(65vw - 266px);
        height: calc(100vh - 170px);
        margin: 60px 133px 0;

        .ok2land__signup-form-box {
            height: 100%;

            .signup-outterForm {
                height: 100%;
            }

            .signup-innerForm {
                height: 100%;
                position: relative;

                .error-message {
                    font-family: $fontSpaceGrotesk;
                    font-size: 14px;
                    font-style: italic;
                    margin-top: 6px;
                    color: $ok2land_red;
                }

                .ok2land__signup-title {
                    color: $ok2land_gray;
                    font-weight: 700;
                    font-size: 48px;
                    font-family: $fontSpaceGrotesk;
                    margin-bottom: 54px;
                    display: block;
                    letter-spacing: 6px;
                }

                .ok2land__st-hint {
                    color: #2E2E3F7A;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: $fontSpaceGrotesk;
                    letter-spacing: 2px;
                    margin-top: 16px;
                    display: block;

                    .ok2land__sth-strong {
                        font-weight: 700;
                        color: $ok2land_black;
                    }
                }

                .ok2land__signup-text {
                    display: block;
                    margin-top: -40px;
                    margin-bottom: 56px;
                    font-family: $fontSpaceGrotesk;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 2px;
                    color: $ok2land_gray;
                }
        
                label { 
                    display: block;
                    margin-bottom: 9px;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: $fontSpaceGrotesk;
                    color: $ok2land_black;
                }
        

                input {
                    background-color: #DBF1F599;
                    border: 0;
                    border-radius: 2px;
                    height: 50px;
                    width: 100%;
                    padding: 0 16px;
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $fontSpaceGrotesk;
                }

                input:not(:placeholder-shown),
                input:focus {
                    background-color: #FFFFFF;
                    outline: 0;
                }
        
                input:first-of-type {
                    margin-bottom: 24px;
                }

                .ok2land__days-week {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 30px;
                    margin-bottom: 16px;
                    position: relative;

                    .ok2land__day-week {
                        width: 75px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: $fontSpaceGrotesk;
                        color: #1D1D29CC;
                        border: 1px solid #DBF1F599;
                        background-color: #DBF1F599;
                        cursor: pointer;
                    }
                    .ok2land__day-week.selected {
                        font-weight: 700;
                        font-family: $fontSpaceGrotesk;
                        color: #1D1D29;
                        border: 1px solid #D4E5E8;
                        background-color: #FFFFFF;
                    }   
                    .ok2land__day-week.disabled {
                        opacity: 0.2;
                        cursor: default;
                    }
                    .btn-delete-schedule {
                        position: absolute;
                        right: -40px;
                        top: 10px;
                        border: none;
                        background-color: transparent;

                        img {
                            width: 20px;
                            object-fit: contain;
                        }
                    }  
                }

                .ok2land__btn-add {
                    width: 122px;
                    height: 34px;
                    border-radius: 4px;
                    border: none;
                    font-family: $fontSpaceGrotesk;
                    font-size: 12px;
                    color: #FFFFFF;
                    font-weight: 700;
                    letter-spacing: 2px;
                    background: #1D1D29;
                    text-transform: uppercase;
                    margin-top: 10px;
                }

                .ok2land__btn-add.disabled {
                    opacity: 0.1;
                }
        
                .ok2land__signup-forgot {
                    text-align: right;
                    display: block;
                    margin-top: 16px;
        
                    a{
                        color: $ok2land_black;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }

                .ok2land__top-bar {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-bottom: 2px solid $ok2land_light_blue;
                    margin-bottom: 22px;

                    .ok2land__tb-items {
                        display: flex;
                        padding-bottom: 20px;
                        width: 25%;

                        .ok2land__tbi-numbers {
                            border-radius: 50%;
                            background-color: #2E2E3F14;
                            color: $ok2land_white;
                            margin-right: 10px;
                            display: flex;
                            width: 30px;
                            height: 30px;
                            align-items: center;
                            justify-content: center;
                            font-family: $fontSpaceGrotesk;
                            font-weight: 500;
                            font-size: 14px;
                        }

                        .ok2land__tbi-title {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: $fontSpaceGrotesk;
                            font-size: 16px;
                            color: #2E2E3F7A;
                            font-weight: 400;
                            letter-spacing: 2px;
                        }

                        .ok2land__tbin-active {
                            background-color: $ok2land_black;
                        }

                        .ok2land__tbit-active {
                            color: $ok2land_gray;
                            font-weight: 700;
                        }
                    }

                    .item-active-half {
                        position: relative;
                    }

                    .item-active-half::before {
                        content: "";
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 60%;
                        border-bottom: 4px solid #89BFCA;
                    }

                    .item-active {
                        position: relative;
                    }

                    .item-active::before {
                        content: "";
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 100%;
                        border-bottom: 4px solid #89BFCA;
                    }
                }

                .ok2land__steps {
                    label {
                        margin-bottom: 4px;
                    }

                    input {
                        margin-bottom: 16px;
                    }

                    .ok2land__steps-title {
                        font-family: $fontSpaceGrotesk;
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 2px;
                        margin-bottom: 28px;
                    }

                    .ok2land__steps-content {
                        display: block;
                        margin-top: 16px;

                        .ok2land__sc-club {
                            display: flex;
                            gap: 10px;

                            .ok2land__scc-12 {
                                width: 100%;
                            }

                            .ok2land__scc-10 {
                                width: 75%;
                            }

                            .ok2land__scc-2 {
                                width: 25%;
                            }

                            .ok2land__scc-5 {
                                width: 50%;
                            }
                        }
                    }
                }

                .ok2land__request-land {
                    height: 80px;
                    width: 100%;
                    background-color: #DBF1F599;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    margin-top: 20px;

                    p {
                        margin:0;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: "Space Grotesk";
                        color: #1D1D29;
                    }

                    label {
                        position: relative;
                        display: inline-block;
                        height: 22px;
                        width: 40px;
                    }
            
                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                    
                    span {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #2E2E3F14;
                        transition: 0.3s;
                        border-radius: 30px;
                    }
                    span:before {
                        position: absolute;
                        content: "";
                        height: 22px;
                        width: 22px;
                        left: 0;
                        bottom: 0;
                        background-color: #2E2E3F3D;
                        border-radius: 50%;
                        transition: 0.3s;
                    }
                      
                    input:checked + span {
                        background-color: #2E2E3F3D;
                    }
                      
                    input:checked + span:before {
                        transform: translateX(19px);
                        background-color: #1D1D29;
                    }
                }
                .ok2land__extra-service {
                    height: 80px;
                    width: 100%;
                    background-color: transparent;
                    display:flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px;
                    margin-top: 20px;
                    border-radius: 2px;
                    background-color: #DBF1F599;

                    p {
                        margin:0;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: "Space Grotesk";
                        color: #1D1D29;
                        display: flex;
                        align-items: center;
                    }
                    p.selected {
                        font-size: 16px;
                        font-weight: 700;
                        color: #1D1D29;
                    }

                    img {
                        filter: invert(89%) sepia(11%) saturate(498%) hue-rotate(155deg) brightness(88%) contrast(77%);
                        height: 20px;
                        margin-right: 10px;
                        opacity: 0.4;
                    }
                    img.selected {
                        filter: invert(92%) sepia(3%) saturate(3776%) hue-rotate(145deg) brightness(92%) contrast(84%);
                    }

                    label {
                        position: relative;
                        display: inline-block;
                        height: 22px;
                        width: 40px;
                        margin: 0;
                    }
            
                    input[type="checkbox"] {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }

                    .ok2land__extra-service-input-container {
                        position: absolute;
                        right: 90px;

                        input[type="number"] {
                            margin:0;
                            width: 230px;
                            padding-right: 40px;
                            background-color: #DBF1F599 !important;
                        }
                        p{
                            font-size: 16px;
                            font-weight: 400;
                            font-family: "Space Grotesk";
                            color: #99B8BE;
                            position: absolute;
                            right: 20px;
                            top: 13px;
                            margin: 0;
                        }
                    }

                    
                    
                    span {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background: #2E2E3F14;
                        transition: 0.3s;
                        border-radius: 30px;
                    }
                    span:before {
                        position: absolute;
                        content: "";
                        height: 22px;
                        width: 22px;
                        left: 0;
                        bottom: 0;
                        background-color: #2E2E3F3D;
                        border-radius: 50%;
                        transition: 0.3s;
                    }
                      
                    input:checked + span {
                        background-color: #2E2E3F14;
                    }
                      
                    input:checked + span:before {
                        transform: translateX(19px);
                        background-color: #9FD3DA;
                    }
                }
                .ok2land__extra-service.selected{
                    background-color: #FFFFFF;
                }
            }
        }
    }

    .ok2land_sft {
        height: calc(100vh - 142px);
        margin: 32px 133px 0;
    }

    .ok2land__signup-form-bottom-box {
        width: calc(65vw - 266px);
        margin: 0 133px;

        .ok2land__signup-form-bottom {
            display: flex;
            justify-content: space-between;

            .ok2land__signup-signup {
                font-family: $fontSpaceGrotesk;
                font-size: 14px;
                color: $ok2land_black;
                font-weight: 400;
                cursor: context-menu;
                align-items: center;
                display: flex;                                

                a {
                    margin-left: 4px;
                    font-weight: 500;
                    text-decoration: none;
                    color: $ok2land_black;
                }
            }

            .ok2land__btn {
                width: 248px;
                height: 72px;
                border-radius: 10px;
                border: none;
                font-family: $fontSpaceGrotesk;
                text-transform: uppercase;
                color: #FFFFFF52;
                font-weight: 700;
                letter-spacing: 2px;
                background: #2E2E3F14;
                cursor: not-allowed;
            }

            .active-btn {
                background-color: $ok2land_black;
                color: $ok2land_white;
                cursor: pointer !important;
            }
            

            .ok2land__back-btn {
                background-color: transparent;
                border: none;
                font-family: $fontSpaceGrotesk;
                color: $ok2land_black;
                font-weight: 700;
                font-size: 14px;
                letter-spacing: 2px;
                padding: 0;

                img {
                    margin-right: 8px;
                    margin-bottom: 4px;
                }
            }
        }
    }

    .large-bottom {
        width: calc(65vw - 176px);
        margin: 0 88px;
    }

    .ok2land__signup-image-background {
        width: 35vw;
        background: url(../../images/ok2landBgImage.png);
        background-size: contain;
        height: 100vh;
        background-repeat: round;
        background-size: cover;
        background-position: right;
        height: 100vh;
        background-repeat: no-repeat;
    }
    
}