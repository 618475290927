.mg-top-24 {
    margin-top: 24px !important;
}

.mg-bottom-16 {
    margin-bottom: 16px !important;
}

.center__content {
    align-self: center;

    span {
        font-family: $fontSpaceGrotesk;
        font-weight: 700;
        color: #3E4851E0;
    }
}

.f16 {
    span {
        font-size: 16px;
    }
}

.f14 {
    span {
        font-size: 14px;
    }
}

.lpad {
    padding-left: 26px;
    padding-right: 0;
}

.lpad_ppr {
    padding-left: 58px;
    justify-content: space-between;
    display: flex;

    img {
        cursor: pointer;
    }
}

.lpad_ppr_title {
    padding-left: 38px;
}

.pad-24 {
    padding: 24px;
}

.pad-0-24 {
    padding: 0 24px;
}

.pad-0 {
    padding: 0 !important;
}

.ok2land__row-expanded {
    border-top: 1px solid #BDCCCF;
    margin-top: 16px;

    .ok2land__expanded-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #D4E5E8;

        .ok2land__ef-title {
            color: #3E4851E0;
            font-size: 14px;
            font-weight: 500;
            font-family: $fontSpaceGrotesk;
            letter-spacing: 1px;
        }

        .ok2land__ef-content {
            color: $ok2land_dark_blue;
            font-size: 14px;
            font-weight: 700;
            font-family: $fontSpaceGrotesk;
            letter-spacing: 1px;
        }
    }

    .ok2land__expanded-flex:last-child {
        border-bottom: 0;
    }
}

.flex-start {
    justify-content: flex-start !important;
}

.space-between {
    justify-content: space-between !important;
}

.hide {
    display: none;
}