.ok2land__container {
    display: block;
    margin-top: 72px;

    .ok2land__row {
        max-width: 960px;
        margin: auto;

        .ok2land__aircraft-title {
            display: flex;
            margin-bottom: 24px;

            span {
                margin-left: 12px;
                font-family: $fontSpaceGrotesk;
                font-weight: 700;
                font-size: 14px;
                color: $ok2land_gray;
            }
        }

        .ok2land__aircraft-requests-box {
            margin-bottom: 56px;

            .ok2land__aircraft-requests-row {
                margin-bottom: 16px;

                .ok2land__aircraft-requests-cols{
                    display: grid;

                    span {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 12px;
                        color: #2E2E3F7A;
                    }
                }

                div:nth-child(1) {
                    padding-left: 24px;
                }

                div:nth-child(2) {
                    padding-left: 18px;
                }

                div:nth-child(3) {
                    padding-left: 6px;
                }

                div:nth-child(4) {
                    padding-left: 0px;
                    padding-right: 0;
                    margin-left: -2px;
                }

                div:nth-child(5) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: -8px;
                }

                div:nth-child(6) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: -2px;
                }
            }

            .ok2land__aircraft-resquests {
                margin-bottom: 16px;

                .ok2land__aircraft-individual-request {
                    display: flex;
                    width: 100%;
                    min-height: 80px;
                    border-radius: 20px;
                    background-color: $ok2land_light_blue;
                    padding: 16px 24px;
                    margin-bottom: 16px;

                    .takeoff_white {
                        width: 48px;
                        height: 48px;
                        background-color: $ok2land_red;
                        border-radius: 16px;

                        img {
                            padding: 12px;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .ppr_buttons {
                        .ok2land__btn {
                            max-width: 72px;
                            height: 28px;
                            padding: 6px 17px;
                            border-radius: 4px;
                            margin-right: 8px;
                            font-family: $fontSpaceGrotesk;
                            font-size: 10px;
                            font-weight: 700;
                            background-color: #F6F9F9;
                            color: $ok2land_dark_blue;
                            border: 0;
                        }

                        .ok2land__btn1 {
                            max-width: 72px;
                            height: 28px;
                            padding: 6px 17px;
                            border-radius: 4px;
                            font-family: $fontSpaceGrotesk;
                            font-size: 10px;
                            font-weight: 700;
                            color: $ok2land_dark_blue;
                            background-color: $ok2land_black;
                            color: #F6F9F9;
                            border: 0;
                        }
                    }
                }

                .ok2land__aircraft-no-landings {
                    width: 100%;
                    min-height: 45px;
                    border-radius: 20px;
                    background-color: $ok2land_light_blue;
                    padding: 10px 24px;
                    margin-bottom: 12px;

                    span {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 14px;
                        height: 100%;
                        color: #2E2E3F7A;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .ok2land__aircraft-upcoming-box {
            margin-bottom: 56px;

            .ok2land__manual {
                height: 28px;
                padding: 8px 12px;
                border-radius: 4px;
                font-family: $fontSpaceGrotesk;
                font-size: 10px;
                font-weight: 700;
                background-color: $ok2land_white;
                color: $ok2land_dark_blue;
                border: 0;
                display: none;
            }

            .ok2land__aircraft-upcoming-row {
                margin-bottom: 16px;

                .ok2land__aircraft-upcoming-cols{
                    display: grid;

                    span {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 12px;
                        color: #2E2E3F7A;
                    }
                }

                div:nth-child(1) {
                    padding-left: 20px;
                }

                div:nth-child(2) {
                    padding-left: 18px;
                }

                div:nth-child(3) {
                    padding-left: 6px;
                }

                div:nth-child(4) {
                    padding-left: 0px;
                    padding-right: 0;
                    margin-left: -2px;
                }

                div:nth-child(5) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: -8px;
                }

                div:nth-child(6) {
                    padding-left: 0;
                    padding-right: 0;
                    margin-left: -2px;
                }
            }

            .ok2land__aircraft-upcoming {
                margin-bottom: 16px;

                .ok2land__aircraft-individual-upcoming {
                    display: flex;
                    width: 100%;
                    min-height: 45px;
                    border-radius: 20px;
                    background-color: $ok2land_white;
                    box-shadow: 0px 4px 16px -12px #1A1B1D14;
                    padding: 10px 24px;
                    margin-bottom: 12px;

                    .takeoff_white {
                        width: 48px;
                        height: 48px;
                        background-color: $ok2land_white;
                        border-radius: 16px;

                        img {
                            padding: 14px;
                        }
                    }

                    .takeoff_red {
                        width: 48px;
                        height: 48px;
                        img {
                            padding: 12px;
                            padding-left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .span_ppr {
                        background-color: $ok2land_green;
                        padding: 4px 8px;
                        font-family: $fontSpaceGrotesk;
                        font-weight: 700;
                        font-size: 10px;
                        border-radius: 4px;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }

                    .fix-height {
                        height: 25px;
                        align-items: center;
                        display: flex;
                    }
                }

                .ok2land__aircraft-no-up-landings {
                    width: 100%;
                    min-height: 45px;
                    border-radius: 20px;
                    background-color: $ok2land_white;
                    box-shadow: 0px 4px 16px -12px #1A1B1D14;
                    padding: 10px 24px;
                    margin-bottom: 12px;
                    
                    span {
                        font-family: $fontSpaceGrotesk;
                        font-weight: 500;
                        font-size: 14px;
                        color: #2E2E3F7A;
                        height: 100%;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .services-col {
            gap: 8px;
            display: flex;

            img {
                padding: 6px;
                background-color: $ok2land_light_green;
                border-radius: 4px;
            }
        }
            

        .ok2land__row-expanded {
            border-top: 1px solid #BDCCCF;
            margin-top: 16px;

            .ok2land__expanded-flex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0;
                border-bottom: 1px solid #D4E5E8;

                .ok2land__ef-title {
                    color: #3E4851E0;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: $fontSpaceGrotesk;
                    letter-spacing: 1px;
                }

                .ok2land__ef-content {
                    color: $ok2land_dark_blue;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $fontSpaceGrotesk;
                    letter-spacing: 1px;
                }
            }

            .ok2land__expanded-flex:last-child {
                border-bottom: 0;
            }
        }
    }
}

.modal__background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2E2E3FA3;
    z-index: 999;
    display: none;
    justify-content: center;
    align-items: center;

    .modal__add-manually {
        position: absolute;
        width: 754px;
        height: 588px;
        border-radius: 20px;
        background-color: #F6F9F9;
        padding: 48px 40px 40px;

        .add__landing-title {
            font-size: 22px;
            font-family: $fontSpaceGrotesk;
            font-weight: 500;
            color: $ok2land_black;
            margin-bottom: 22px;
        }

        label { 
            display: block;
            margin-bottom: 9px;
            font-size: 14px;
            font-weight: 400;
            font-family: $fontSpaceGrotesk;
            color: $ok2land_black;
        }

        input, select {
        input {
            background-color: #DBF1F599;
            border: 0;
            border-radius: 2px;
            height: 50px;
            width: 100%;
            padding: 0 16px;
            cursor: pointer;
        }

        .add__landing-form-bottom-box {
            bottom: 40px;
            position: absolute;
            left: 40px;
            right: 40px;
            display: flex;
            justify-content: space-between;

            span {
                display: flex;
                align-items: center;
                font-family: $fontSpaceGrotesk;
                font-size: 14px;
                color: #2E2E3F7A;
                font-weight: 100;
            }

            .add__landing-form-bottom {
                display: flex;
                justify-content: flex-end;
                gap: 8px;
    
                .ok2land__btn {
                    width: 160px;
                    height: 56px;
                    border-radius: 16px;
                    border: none;
                    font-family: $fontSpaceGrotesk;
                    font-weight: 700;
                    letter-spacing: 2px;
                    background-color: $ok2land_black;
                    color: $ok2land_white;
                }

                .btn_white {
                    background-color: $ok2land_white;
                    color: $ok2land_black;
                }

                .btn_black {
                    background-color: $ok2land_black;
                    color: $ok2land_white;
                }
            }
        }
    }
}

    .show-modal {
        display: flex;
    }
}