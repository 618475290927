@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import '../styles/general/breakpoints.scss';
@import '../styles/general/colors.scss';
@import '../styles/general/variables.scss';
@import '../styles/general/global.scss';
@import '../styles/components/screen.scss';
@import '../styles/components/header.scss';
@import '../styles/pages/aircraft-movements.scss';
@import '../styles/pages/dashboard.scss';
@import '../styles/pages/settings.scss';
@import '../styles/pages/sign-in.scss';
@import '../styles/pages/signup.scss';