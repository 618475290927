.ok2land__login-box {
    display: flex;

    .ok2land__logo {
        width: auto;
        height: auto;
        position: absolute;
        left: 32px;
        top: 24px;
    }

    .ok2land__login-form {
        width: calc(65vw - 266px);
        height: calc(100vh - 148px);
        margin: 148px 133px 0;

        .ok2land__login-form-box {
            height: 100%;

            .signup-outterForm {
                height: 100%;
            }

            form {
                height: 100%;

                .login-innerForm {
                    height: 100%;
                    position: relative;

                    .error-message {
                        font-family: $fontSpaceGrotesk;
                        font-size: 14px;
                        font-style: italic;
                        margin-top: 6px;
                        color: $ok2land_red;
                    }

                    .ok2land__login-title {
                        color: $ok2land_gray;
                        font-weight: 700;
                        font-size: 48px;
                        font-family: $fontSpaceGrotesk;
                        margin-bottom: 54px;
                        display: block;
                        letter-spacing: 6px;
                    }
            
                    label { 
                        display: block;
                        margin-bottom: 9px;
                        font-size: 14px;
                        font-weight: 400;
                        font-family: $fontSpaceGrotesk;
                        color: $ok2land_black;
                    }
            
                    input {
                        background-color: #DBF1F599;
                        border: 0;
                        border-radius: 2px;
                        height: 50px;
                        width: 100%;
                        padding: 0 16px;
                    }
            
                    input:nth-child(2) {
                        margin-bottom: 24px;
                    }
            
                    .ok2land__login-forgot {
                        text-align: right;
                        display: block;
                        margin-top: 16px;
            
                        a{
                            color: $ok2land_black;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
            
                    .ok2land__login-form-bottom-box {
                        bottom: 38px;
                        position: absolute;
                        left: 0;
                        right: 0;

                        .ok2land__login-form-bottom {
                            display: flex;
                            justify-content: space-between;
                
                            .ok2land__login-signup {
                                font-family: $fontSpaceGrotesk;
                                font-size: 14px;
                                color: $ok2land_black;
                                font-weight: 400;
                                cursor: context-menu;
                                align-items: center;
                                display: flex;                                
                
                                a {
                                    margin-left: 4px;
                                    font-weight: 500;
                                    text-decoration: none;
                                    color: $ok2land_black;
                                }
                            }
                
                            .ok2land__btn {
                                width: 248px;
                                height: 72px;
                                border-radius: 10px;
                                border: none;
                                font-family: $fontSpaceGrotesk;
                                color: #FFFFFF52;
                                font-weight: 700;
                                letter-spacing: 2px;
                                background: #2E2E3F14;
                            }

                            .active-btn {
                                background-color: $ok2land_black;
                                color: $ok2land_white;
                            }
                        }
                    }
                }
            }
        }
    }

    .ok2land__login-image-background {
        width: 35vw;
        background: url(../../images/ok2landBgImage.png);
        background-size: contain;
        height: 100vh;
        background-repeat: round;
    }

    .mb-20{
        margin-bottom: 20px;
    }
    
}